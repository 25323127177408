import { Box, Flex, Spacer, useDisclosure, VStack, Text, Container } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { DrawerContent, DrawerOverlay, Drawer } from "@chakra-ui/react";
import { FaHome, FaPhone, FaClipboardList, FaUsers, FaThLarge } from "react-icons/fa";

const handleClick = () => {
    const message = `Olá `;
    const whatsappURL = `https://wa.me/+5538999871283/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

function Menu() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box bg={'linear-gradient(to right, #331C5A, #28546C)'} pt={2} pb={2} position="fixed" top={0} width="100%" zIndex={999}>
            <Container maxW="container.xl">
                <Flex
                    display={{ base: "none", md: "flex" }}
                    pt={1}
                    alignItems="center"
                    color={'white'}
                >
                    <Box>
                        <Text fontFamily={'League Spartan, sans-serif'} fontSize={"x-large"} fontWeight="bold" ml={2} h={6}><a href="https://codaz.com.br">CODAZ</a></Text>
                    </Box>
                    <Spacer />
                    <Box display="flex" alignItems="center">
                        <Text mr={8} fontSize="lg">
                            <a href="#home">Início</a>
                        </Text>
                        <Text mr={8} fontSize="lg">
                            <a href="#services">Serviços</a>
                        </Text>
                        <Text mr={8} fontSize="lg">
                            <a href="#quemsomos">Quem somos</a>
                        </Text>
                        <Text mr={8} fontSize="lg">
                            <a href="#cases">Cases de Sucesso</a>
                        </Text>
                        <Text mr={8} fontSize="lg"  onClick={handleClick} colorScheme='teal' size='lg' bgColor={'#5200A0'} p={2} borderRadius={20}>
                            <a href="#cases">Fale conosco</a>
                        </Text>
                    </Box>
                </Flex>

                <Box display={{ base: "flex", md: "none" }} p={1} onClick={onOpen} color={'white'}>
                    <HamburgerIcon w={6} h={6} mt={1} />
                    <Text fontFamily={'League Spartan, sans-serif'} fontSize={"x-large"} fontWeight="bold" ml={2} h={6}><a href="https://codaz.com.br">CODAZ</a></Text>
                </Box>

                <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
                    <DrawerOverlay />
                    <DrawerContent bg="gray.900" alignItems="left" color={"white"} >
                        <VStack p={4} spacing={4} alignItems="left">
                            <Box display={{ base: "block", md: "none" }}>
                                <Text fontFamily={'League Spartan, sans-serif'} fontSize={"x-large"} fontWeight="bold" ml={2} h={6}><a href="https://codaz.com.br">CODAZ</a></Text>
                            </Box>
                            <VStack spacing={4} alignItems="flex-start">
                                <Text fontSize="lg" fontWeight="bold">
                                    <a href="#home">
                                        <Flex alignItems="center">
                                            <FaHome style={{ marginRight: "8px" }} />
                                            Início
                                        </Flex>
                                    </a>
                                </Text>
                                <Text fontSize="lg" fontWeight="bold">
                                    <a href="#services">
                                        <Flex alignItems="center">
                                            <FaClipboardList style={{ marginRight: "8px" }} />
                                            Serviços
                                        </Flex>
                                    </a>
                                </Text>
                                <Text fontSize="lg" fontWeight="bold">
                                    <a href="#quemsomos">
                                        <Flex alignItems="center">
                                            <FaUsers style={{ marginRight: "8px" }} />
                                            Quem somos
                                        </Flex>
                                    </a>
                                </Text>
                                <Text fontSize="lg" fontWeight="bold">
                                    <a href="#cases">
                                        <Flex alignItems="center">
                                            <FaThLarge style={{ marginRight: "8px" }} />
                                            Cases de Sucesso
                                        </Flex>
                                    </a>
                                </Text>
                                <Text fontSize="lg" fontWeight="bold" onClick={handleClick} colorScheme='teal' size='lg' bgColor={'#5200A0'} p={2} borderRadius={20}>
                                    <a href="#fale">
                                        <Flex alignItems="center">
                                            <FaPhone style={{ marginRight: "8px" }} />
                                            Fale conosco
                                        </Flex>
                                    </a>
                                </Text>
                            </VStack>
                        </VStack>
                    </DrawerContent>
                </Drawer>
            </Container>
        </Box>
    );
}

export default Menu;
