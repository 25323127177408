import Menu from '../../component/menu';
import Banner from '../../component/banner';
import GridCards from '../../component/gridCards';
import About from '../../component/about';
import Cases from '../../component/cases';
import Footer from '../../component/footer';

function Home() {
    return (
        <>
            <Menu />
            <Banner />
            <GridCards />
            <About />
            <Cases />
            <Footer 
            email="contato@codaz.com.br"
            phone="(38) 99987-1283"
            address="Unaí - MG"
            />
        </>
    );
}

export default Home;