import { Box, Flex, Text, Container } from '@chakra-ui/react';

function about() {
    return (
        <Flex bg={'gray.100'} alignItems="center" justifyContent="center" id='quemsomos'>
            <Container maxW="container.xl">
                <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent="center"
                    pt={100}
                    pb={100}
                >
                    <Box
                        flex={{ base: "none", md: 1 }}
                        mb={{ base: 4, md: 0 }}
                        mr={{ base: 0, md: 4 }}
                        color={'white'}
                    >
                        <Text fontSize="3xl" color={'#331C5A'}>Quem somos?</Text>
                        <Text mt={2} fontSize="md" color={'black'}>
                            Somos uma empresa de desenvolvimento de software localizada em Unai, MG. Nosso foco é fornecer soluções de software inovadoras e personalizadas para atender às necessidades específicas de nossos clientes.
                            Nossa equipe altamente qualificada e experiente está comprometida em oferecer serviços de alta qualidade, garantindo a satisfação e o sucesso de nossos clientes. Estamos dedicados a impulsionar o crescimento e a inovação por meio da tecnologia.
                        </Text>
                    </Box>
                </Flex>
            </Container>
        </Flex>
    );
}

export default about;
