import { Box, Flex, Text, Container, Stack, Button, Image } from '@chakra-ui/react';
import banner from "./../../assets/banner_dev.svg";
import "./banner.css";

const handleClick = () => {
    const message = `Eu gostaria de fazer um orçamento.`;
    const whatsappURL = `https://wa.me/+5538999871283/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

function Banner() {
    return (
        <Flex bg={'linear-gradient(to right, #331C5A, #28546C)'} alignItems="center" justifyContent="center" id='home'>
            <Container maxW="container.xl">
                <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent="center"
                    pt={100}
                    pb={100}
                >
                    <Box
                        flex={{ base: "none", md: 1 }}
                        mb={{ base: 4, md: 0 }}
                        mr={{ base: 0, md: 4 }}
                        color={'white'}
                    >
                        <Text fontSize="5xl" lineHeight={{ base: "1.2", md: "normal" }}>Transforme suas ideias em realidade</Text>
                        <Text mt={2} fontSize="xl">
                            Com a Codaz Tecnologia, transformamos seu conceito em soluções de software inovadoras e eficazes.
                        </Text>
                        <Stack spacing={4} direction='row' align='center'>
                            <Button colorScheme='teal' size='lg' bgColor={'#5200A0'} mt={20} onClick={handleClick} color={'white'}>
                                Solicitar um orçamento
                            </Button>
                        </Stack>
                    </Box>

                    <Box flex={{ base: "none", md: 1 }} display="flex" justifyContent="center">
                        <Image w='80%' src={banner} className="animated-image" />
                    </Box>
                </Flex>
            </Container>
        </Flex>
    );
}

export default Banner;
