import { Flex, Text, Container, SimpleGrid, Button, Stack } from '@chakra-ui/react';
import neovitalle from './../../assets/neovitalle.svg';
import solange from './../../assets/solange.svg';
import deskx from './../../assets/deskx.svg';
import coagril from './../../assets/coagril.svg';
import centrolab from './../../assets/centrolab.svg';
import saraalves from './../../assets/saraalves.svg';
import './index.css'

const handleClick = () => {
    const message = `Eu gostaria de fazer um orçamento.`;
    const whatsappURL = `https://wa.me/+5538999871283/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
};

function cases() {
    return (
        <Flex bg={'white'} pt={150} pb={150} justifyContent="center" id='cases'> {/* Centraliza o conteúdo horizontalmente */}
            <Container maxW="container.xl">
            <Text fontSize="3xl" color={'#331C5A'}>Cases de Sucesso</Text>
                <SimpleGrid spacing={4} columns={{ base: 2, md: 6 }} alignItems="center"> {/* Define o número de colunas e alinha os itens no centro */}
                    <div className="imageContainer">
                        <img
                            className="grayScaleImage"
                            width={150}
                            src={coagril}
                            alt="Coagril"
                        />
                    </div>
                    <div className="imageContainer">
                        <img
                            className="grayScaleImage"
                            width={150}
                            src={centrolab}
                            alt="Centrolab"
                        />
                    </div>
                    <div className="imageContainer">
                        <img
                            className="grayScaleImage"
                            width={150}
                            src={neovitalle}
                            alt="Neovitalle"
                        />
                    </div>
                    <div className="imageContainer">
                        <img
                            className="grayScaleImage"
                            width={150}
                            src={solange}
                            alt="Dra Solange"
                        />
                    </div>
                    <div className="imageContainer">
                        <img
                            className="grayScaleImage"
                            width={150}
                            src={deskx}
                            alt="Deskx Consultoria"
                        />
                    </div>
                    <div className="imageContainer">
                        <img
                            className="grayScaleImage"
                            width={100}
                            src={saraalves}
                            alt="Deskx Consultoria"
                        />
                    </div>
                </SimpleGrid>
                <Stack spacing={4} direction='row' justifyContent="center">
                    <Button colorScheme='teal' size='md' bgColor={'#5200A0'} mt={20} onClick={handleClick} color={'white'}>
                        Solicitar um orçamento
                    </Button>
                </Stack>
            </Container>
        </Flex>
    );
}

export default cases;
