import React from 'react';
import { Flex, Box, Text, Link, Icon, Stack, Divider, Container } from '@chakra-ui/react';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';

interface ContactInfo {
  email: string;
  phone: string;
  address: string;
}

const handleClick = () => {
    const message = `Eu gostaria de fazer um orçamento.`;
    const whatsappURL = `https://wa.me/+5538999871283/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

const FooterWithContactInfo: React.FC<ContactInfo> = ({ email, phone, address }) => {
  return (
    <Flex
      as="footer"
      bg="gray.800"
      color="white"
      py={15}
      px={4}
      align="flex-start"
      justify="space-between"
      flexWrap="wrap"
    >
      <Box bg="gray.800" w="100%" pt={10}>
        <Container maxW="container.xl">
          <Flex flexWrap="wrap" justify="space-between">
            {/* Coluna com a logo e o resumo da empresa */}
            <Box width={{ base: '100%', md: '40%' }} mb={{ base: 8, md: 0 }}>
              <Text fontFamily={'League Spartan, sans-serif'} fontSize="2xl" fontWeight="bold" mb={4}><a href="https://codaz.com.br">CODAZ</a></Text>
              <Text color="whiteAlpha.700" fontSize="sm">
              Desenvolvimento de software personalizado em Unaí, MG. Soluções inovadoras para o seu negócio. Impulsionando o crescimento e a inovação por meio da tecnologia.
              </Text>
            </Box>

            {/* Coluna com os menus do site */}
            <Box width={{ base: '100%', md: '20%' }} mb={{ base: 8, md: 0 }}>
              <Stack spacing={2}>
                <Link href="#home" color="whiteAlpha.700">Home</Link>
                <Link href="#services" color="whiteAlpha.700">Serviços</Link>
                <Link href="#quemsomos" color="whiteAlpha.700">Quem Somos</Link>
                <Link href="#cases" color="whiteAlpha.700">Cases de Sucesso</Link>
                <Link color="whiteAlpha.700" onClick={handleClick}>Orçamento</Link>
              </Stack>
            </Box>

            {/* Coluna com os dados de contato */}
            <Box width={{ base: '100%', md: '20%' }} mb={{ base: 8, md: 0 }}>
              <Stack spacing={4}>
                <Stack direction="row" align="center">
                  <Link href={`mailto:${email}`} color="whiteAlpha.800"><Icon as={FiMail} boxSize={5} color="whiteAlpha.800" /> {email}</Link>
                </Stack>
                <Stack direction="row" align="center">
                  <Text color="whiteAlpha.800"><Icon as={FiPhone} boxSize={5} color="whiteAlpha.800" /> {phone}</Text>
                </Stack>
                <Stack direction="row" align="center">
                  <Text color="whiteAlpha.800"><Icon as={FiMapPin} boxSize={5} color="whiteAlpha.800" /> {address}</Text>
                </Stack>
              </Stack>
            </Box>
          </Flex>
        </Container>
      </Box>

      {/* Linha separadora */}
      <Divider my={10} w="100%" borderColor="gray.600" />

      {/* Linha com os dados de direitos reservados */}
      <Text fontSize="sm" color="whiteAlpha.600" width="100%" textAlign="center">&copy; {new Date().getFullYear()} Codaz Tecnologia. Todos os direitos reservados.</Text>
    </Flex>
  );
};

export default FooterWithContactInfo;
